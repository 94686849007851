import React, { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';

// Imports 

import { primaryClr, primaryTxtClr, welcomeClr, whiteClr } from '../../Components/colors';
import WindowImage from "../../Images/window.png";
import UmbrellaImage from "../../Images/umbrella.png";
import "./index.css";


const Itinerary = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view
    const [sDressCode, setDressCode] = useState(false);

    const handleOpen = () => setDressCode(true);
    const handleClose = () => setDressCode(false);

    return(
        <Box id="itinerary" style={{ backgroundColor: welcomeClr, width:"100%", height: "auto", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", paddingTop:'5rem', paddingBottom:"5rem" }} >
            <p className='title-style-head2' style={{  fontFamily: 'Times New Roman', color: primaryTxtClr, marginTop:"3rem", marginBottom:"3rem", textDecoration:'none' }} >
               ITINERARY
            </p>

            <Grid container spacing={2} style={{ marginTop: '2rem', marginBottom: '2rem', display:"flex", alignItems:"center", justifyContent:"center" }} >

                <Grid item xs={12} md={12} lg={5} xl={5} >
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column" }} >
                        <img src={WindowImage} className='img-style' style={{ height:"auto" }} />
                        
                        <p  className='Title-style' style={{ fontSize: isMobile? '1rem' : '1.5rem', textDecoration:'none', fontFamily: 'CustomFont', fontStyle:"italic", fontWeight: 200, color: primaryTxtClr, marginTop:"1rem", marginBottom:"1rem" }} >
                            The Wedding
                        </p>
                        
                        <p  className='subTitle-style' style={{  textDecoration:'none', fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 'normal', color: primaryTxtClr,  marginTop:"1rem" }} >
                            Thursday, December 19th, 17:00
                        </p>
                        
                        <p  className='subTitle-style' style={{  textDecoration:'none', fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 'normal', color: primaryTxtClr, }} >
                            Location: La Maison Bleue
                        </p>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={2} xl={2} >
                    <div style={{ border:`solid 0.5px ${primaryTxtClr}` }} />
                </Grid>

                <Grid item xs={12} md={12} lg={5} xl={5} >
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column" }} >
                        <img src={UmbrellaImage} className='img-style2' style={{ height:"auto" }} />
                        
                        <p className='Title-style' style={{ fontSize: isMobile? '1rem' : '1.5rem', textAlign:'center', textDecoration:'none', fontFamily: 'CustomFont', fontStyle:"italic", fontWeight: 200, color: primaryTxtClr, marginTop:"1rem", marginBottom:"1rem", width: isMobile && '60%', lineHeight:2 }} >
                            Lunch with the Bunch, Party at Sunset
                        </p>
                        
                        <p className='subTitle-style' style={{ textAlign:'center', textDecoration:'none', fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 'normal', color: primaryTxtClr, marginTop:"1rem" }} >
                            Friday, December 20th, 13.00 – 19.00
                        </p>
                        
                        <p className='subTitle-style' style={{ textAlign:'center', textDecoration:'none', fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 'normal', color: primaryTxtClr }} >
                            Location: Zuni
                        </p>
                    </div>
                </Grid>

            </Grid>

            <Button variant='outlined' onClick={handleOpen} 
                style={{ 
                    color: primaryTxtClr, 
                    border: `solid 1.5px ${primaryTxtClr}`, 
                    // minWidth:'17%', 
                    // maxWidth:'65%', 
                    // marginTop: '5rem', 
                    // width:"15%",
                    fontSize:'0.85rem', 
                    fontStyle:'italic', 
                    fontFamily:"sans-serif", 
                    fontWeight: 200, 
                    // alignSelf:"flex-end", 
                    // marginRight: '10rem'
                }} 
                className='button-style'
            >
                Dresscode
            </Button>

            {/* ******  Modal For Flight...  ****** */}
            <Modal
                open={sDressCode}
                onClose={handleClose}  // Close on background click
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    style={{
                        minWidth: '40%',
                        maxWidth: '75%',
                        // backgroundColor:'red',
                        backgroundColor: whiteClr,
                        borderRadius: '10px',  // Slight rounding of the corners
                        position: 'relative',
                        padding: '2rem',
                        overflowX:'scroll',
                        maxHeight: '80%',
                        paddingTop:'3rem',
                        paddingBottom:'5rem',
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleClose}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography
                        variant="body1"
                        style={{
                            fontSize: '1.5rem',
                            fontWeight: 200,
                            fontFamily: "Times New Roman",
                            fontStyle: 'italic',
                            color: primaryTxtClr,
                            marginBottom: '1.5rem',
                            textAlign:"center",
                            marginBottom:'3rem',
                        }}
                    >
                        Dress Code
                    </Typography>

                    {/* Flex container for image and text */}
                    <Grid
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        container 
                        spacing={2}
                    >
                        <Grid item xs={12} md={12} lg={5} xl={5} style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                         
                                    {/* <img
                                        src={WindowImage}
                                        alt="Road Map"
                                        style={{
                                            // width: '50%',
                                            maxHeight: isMobile? 250 : 400,
                                            minHeight: isMobile ? 200 : 350,
                                            marginRight: '2rem',
                                            borderRadius: '10px',
                                        }}
                                    /> */}
                    
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 200,
                                    fontFamily: "Times New Roman",
                                    fontStyle: 'italic',
                                    color: primaryTxtClr,
                                }}
                            >
                                  La Maison Bleue - Formal
                            </Typography>
                            
                        </Grid>
                        
                        <div xs={12} md={12} lg={2} xl={2} className='divider-parent' style={{ display:"flex", alignItems:"center", justifyContent:"center"}} >
                            <div className="divider1" />
                        </div>

                        {/* Right side (Text) */}
                        <Grid item xs={12} md={12} lg={5} xl={5} style={{ textAlign: 'center' }}>
                            {/* <Typography
                                variant="body1"
                                style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 200,
                                    fontFamily: "Times New Roman",
                                    fontStyle: 'italic',
                                    color: primaryTxtClr,
                                    marginBottom: '1.5rem',
                                }}
                            >
                                Dress Code
                            </Typography> */}
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 200,
                                    fontFamily: "Times New Roman",
                                    fontStyle: 'italic',
                                    color: primaryTxtClr,
                                }}
                            >
                               Zuni - Resort wear
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}

export default Itinerary;
