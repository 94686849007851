import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { Button, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Local 

import { primaryClr, primaryTxtClr, welcomeClr, whiteClr } from '../../Components/colors';
import CloseIcon from '@mui/icons-material/Close';
import WindowImage from "../../Images/window.png";
import "./index.css";
import { GET_GUEST_MESSAGES, POST_GUEST_MESSAGE_API } from '../Server/API';
import { httpRequest } from '../Server/Axios';

const messageData = [
    {
        id: 0,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

    {
        id: 2,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

    {
        id: 3,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

    {
        id: 1,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

    {
        id: 4,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

    {
        id: 5,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

    {
        id: 6,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },
    {
        id: 7,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

    {
        id: 8,
        message:"Type here short message..Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message...Type here short message....",
        from:"Name Surname"
    },

]

const useStyles = makeStyles((theme) => ({
    guestBookContainer: {
      display: 'flex',
      overflowX: 'auto', // Enable horizontal scrolling
      whiteSpace: 'nowrap', // Prevents items from wrapping to next line
      padding: '1rem',
      gap: '1rem',
      '&::-webkit-scrollbar': {
        height: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
    },
    messageBox: {
      minWidth: '250px', // Set the minimum width for each message box
      backgroundColor: '#f0f0f0',
      padding: '1rem',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      fontFamily: "'Open Sans', sans-serif",
    },
  }));


const GuestBook = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view

    const [sMessage, setMessage] = useState("");
    const [sName, setName] = useState("");
    const [sMessageModal, setMessageModal] = useState(false);
    const [showMessages, setshowMessages] = useState(false);
    const [sLoader, setLoader ] = useState(false);
    const [sLoader1, setLoader1 ] = useState(false);
    const [listOfMessages, setListOfMessages ] = useState([]);
    const [userDetail, setUserDetail] = useState({})


    const handleOpen = () => setMessageModal(true);
    const handleClose = () => setMessageModal(false);

    const handleOpenSHowMessages = () => setshowMessages(true);
    const handleCloseSHowMessages = () => setshowMessages(false);

    useEffect(() => {
        getGuestMessages();
        const userDetails = localStorage.getItem("user");
        const userValue = JSON.parse(userDetails);
        setUserDetail(userValue);
    }, []);


    const getGuestMessages = async( ) => {
        setLoader(true)
        const LIST_GUEST_MESSAGE = GET_GUEST_MESSAGES( userDetail.id );
        
        try {
          const response = await httpRequest(LIST_GUEST_MESSAGE);
          console.log("***** List of Respoonse Messages ***** ", response);
          if(response.success){
              setLoader(false)
              setListOfMessages(response.messages)
          }
          else{
            setLoader(false)
          }
        } catch (error) {
            setLoader(false)
        }
    }

    const postGuestMessages = async() => {
        setLoader1(true)
        const POST_GUEST_MESSAGE = POST_GUEST_MESSAGE_API( userDetail.id, sName, sMessage );
        
        try {
          const response = await httpRequest(POST_GUEST_MESSAGE);
        //   console.log("***** List of Respoonse Messages ***** ", response);
          if(response.success){
              setLoader1(false);
              setName("");
              setMessage("");
              handleClose();
              getGuestMessages();
          }
          else{
            setLoader1(false)
          }
        } catch (error) {
            setLoader1(false)
        }
    }

    return(
        <Box id="guestbook" style={{ backgroundColor: welcomeClr, width:"100%",  display:"flex", alignItems:"center", justifyContent: 'center', flexDirection:"column", paddingTop: '5rem', paddingBottom: '5rem'}} >
            <p className='Title-style' style={{  fontFamily: 'CustomFont', color: primaryTxtClr , textDecoration:'none'}} >
                GuestBook
            </p>

            <Box style={{
                maxWidth:'90%',
                paddingTop: '5rem',
                padding:"1rem",
                marginTop:'2rem',
            }}>

                <Grid container  style={{ alignItems:"center", justifyContent:"center"}} >
                    {
                        sLoader ? 
                            <CircularProgress color={primaryTxtClr}  />
                        :
                        (
                            listOfMessages ? 
                                listOfMessages.slice(0,3).map((item, index)=> {
                                    return(
                                        <Grid item xs={12} md={6} sm={12} lg={4} xl={4} key={index} style={{  padding:'2rem' }} >
                                            <Typography style={{ fontFamily:"Times New Roman", fontStyle:"italic", color:'gray', textAlign:"center", textDecoration:'none', fontSize: '1.35rem',  }} >{item.message}</Typography>
                                            <Typography style={{ fontFamily:"Times New Roman", fontStyle:"italic", color:'gray', textAlign:"center", textDecoration:'none', fontSize: '0.95rem', marginTop:'1rem' }}>From: {item.name}</Typography>
                                        </Grid>
                                    )
                                })
                                :
                                <Typography style={{ fontFamily:"Times New Roman", fontStyle:"italic", color:'gray', textAlign:"center", fontSize: '0.95rem', marginTop:'3rem' }}>
                                    There are no message
                                </Typography>
                        )
                    }
                        
                </Grid>
                {
                    listOfMessages.length > 3 &&
                        <div style={{ width:"100%", marginTop:"2rem", display:"flex", alignItems:"center", justifyContent:"center" }} >
                            <Button onClick={handleOpenSHowMessages} variant='text' style={{ fontFamily:'Times New Roman', color: primaryTxtClr, padding: '1rem', fontStyle:'normal'}} >
                                View all messages
                            </Button>
                        </div>
                }
            </Box>
               
            

            <Button onClick={handleOpen} variant='outlined' style={{ fontFamily:'Times New Roman', color: primaryTxtClr, border: `solid 2px ${primaryTxtClr}`, minWidth:'23%', maxWidth:"80%", marginTop: '5rem', padding: '1rem', fontStyle:'italic'}} >
                Write your message
            </Button>
            
              {/* ******  Modal For Guest Book Message...  ****** */}
              <Modal
                open={sMessageModal}
                onClose={handleClose}  // Close on background click
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    style={{
                        minWidth: '40%',
                        maxWidth: '75%',
                        // backgroundColor:'red',
                        backgroundColor: whiteClr,
                        borderRadius: '10px',  // Slight rounding of the corners
                        position: 'relative',
                        padding: '2rem',
                        overflowX:'scroll',
                        maxHeight: '80%'
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleClose}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Flex container for image and text */}
                    <Grid
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        container 
                        spacing={2}
                    >
                        {/* Left side (Image) */}
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                            {
                                !isMobile &&
                                    <img
                                        src={WindowImage}
                                        alt="Road Map"
                                        style={{
                                            maxHeight: 400,
                                            minHeight:350,
                                            marginRight: '2rem',
                                            borderRadius: '10px',
                                        }}
                                    />
                            }
                        </Grid>

                        {/* Right side (Text) */}
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ 
                            // backgroundColor:"red", 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection:"column",
                            width:"100%"
                        }} >
                            <input 
                                value={sName}
                                onChange={(e)=> setName(e.target.value)}
                                className='input-style'
                                style={{ 
                                    backgroundColor: welcomeClr,
                                    color: "gray",
                                    fontSize: '1.2rem',
                                    fontFamily: 'Times New Roman',
                                    textAlign: "center",
                                    width: '95%',
                                    lineHeight: '1.5rem',
                                    // marginTop: '8rem',
                                    marginBottom: '1rem',
                                    letterSpacing: 5,
                                    resize: 'none',  // Prevent resizing if needed
                                    padding: '0.75rem',
                                    borderBottom:`solid 1px ${primaryTxtClr}`
                                }}
                                placeholder='Your name'
                                maxLength={42}
                            />

                            <textarea
                                value={sMessage}
                                onChange={(e) => setMessage(e.target.value)}
                                className="input-style"
                                style={{
                                    backgroundColor: welcomeClr,
                                    color: "gray",
                                    fontSize: '1.2rem',
                                    fontFamily: 'Times New Roman',
                                    textAlign: "center",
                                    height: '8rem',  // Increased height for multi-line
                                    width: '90%',
                                    lineHeight: '1.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                    letterSpacing: 3,
                                    resize: 'none',  // Prevent resizing if needed
                                    padding: '2rem',
                                    borderBottom:`solid 1px ${primaryTxtClr}`
                                }}
                                placeholder="Type your message here..."
                                maxLength={250}
                            />
                            <p style={{ fontFamily:'sans-serif', fontStyle:"italic", fontWeight:"lighter", fontSize:'0.95rem' }} > {sMessage.length}/250 </p>

                            <Button onClick={postGuestMessages} variant='outlined' style={{ color: primaryTxtClr, border: `solid 1px ${primaryTxtClr}`, marginTop: '2rem'}} >
                                {
                                    sLoader1 ?
                                        <CircularProgress color='black' size={25} />
                                        :
                                        "Submit"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                open={showMessages}
                onClose={handleCloseSHowMessages}  // Close on background click
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <div
                    style={{
                        backgroundColor: whiteClr,
                        borderRadius: '10px',  // Slight rounding of the corners
                        // position: 'relative',
                        padding: '2rem',
                        overflowY:'scroll',
                        height: 500,
                        display: 'flex', 
                        alignItems: 'center', 
                        // justifyContent: 'center',
                        flexDirection:"column"
                    }}
                    className='view-message-container'
                >
                    <Typography style={{ fontFamily:"Times New Roman", color:'gray', textAlign:"center", fontSize: '1.5rem', marginBottom: '2rem' }} >List of all messages</Typography>

                    {
                        listOfMessages.map((item)=> {
                            return(
                                <div className='view-messages' style={{ marginTop:"2rem" }} >
                                    <Typography style={{ fontFamily:"Times New Roman", fontStyle:"italic", color:'gray', textAlign:"center", textDecoration:'none', fontSize: '1.25rem',  }} >"{item.message}"</Typography> 
                                    <Typography style={{ fontFamily:"Times New Roman", fontStyle:"italic", color:'gray', textAlign:"center", textDecoration:'none', fontSize: '0.95rem', marginTop:'0.25rem' }}>From: {item.name}</Typography> 
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>

        </Box>
    )
}

export default GuestBook;
