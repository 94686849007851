// import React, { useState } from 'react';
// import { Box, TextField, Typography } from '@material-ui/core';
// import { primaryClr, primaryTxtClr } from '../../Components/colors';
// import "./index.css";

// const QuestAndAns = () => {

//     return(
//         <Box id="QandA" style={{ backgroundColor: primaryClr, width:"100%", height: "98vh", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", paddingTop:"5rem" }} >
//             <Typography style={{  fontFamily: 'Times New Roman', fontSize: '2rem', color: primaryTxtClr }} >
//                 Q&A  
//             </Typography>

//            <div className='question-box' >
//                 <Typography style={{ fontStyle:'italic', fontFamily: 'Times New Roman', fontWeight:'bold', fontSize: '1.25rem', color: primaryTxtClr, textAlign:"left" }} >
//                     Q: How is the weather at this time of the year?
//                 </Typography>
//                 <Typography style={{ fontStyle:'italic', fontFamily: 'Times New Roman', fontWeight: 300, fontSize: '1.15rem', color: primaryTxtClr, textAlign:"left" }} >
//                     In December, you can expect warm, sunny days with temperatures around 22-25°C and cooler evenings 16-18°C, making it an ideal spot for a winter wedding. 
//                 </Typography>
//            </div>

//            <div className='question-box' >
//                 <Typography style={{ fontStyle:'italic', fontFamily: 'Times New Roman', fontWeight:'bold', fontSize: '1.25rem', color: primaryTxtClr, textAlign:"left" }} >
//                     Q: When is the best time to arrive to El Gouna 
//                 </Typography>
//                 <Typography style={{ fontStyle:'italic', fontFamily: 'Times New Roman', fontWeight: 300, fontSize: '1.15rem', color: primaryTxtClr, textAlign:"left" }} >
//                     We recommend that guests arrive in Gouna on the evening of the 18th to settle in and relax before the big day. Sara and Khaled will be on standby to meet up with everyone who arrives that day for something casual. To know more please reach out to bride or groom.
//                 </Typography>
//            </div>

//         </Box>
//     )
// }

// export default QuestAndAns;

import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { primaryClr, primaryTxtClr } from '../../Components/colors';
import "./index.css";
import { useMediaQuery, useTheme } from '@mui/material';

const QuestAndAns = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view

    return (
        <div id="QandA" style={{ backgroundColor: primaryClr, minHeight: isMobile? '65vh' : '50vh', display: "flex", alignItems: "center", flexDirection: "column", padding: "1rem", paddingTop: isMobile ? '3rem' : '5rem', paddingBottom: '5rem' }}>
            <p className='title-style-head3' style={{  fontFamily: 'Times New Roman', color: primaryTxtClr, marginTop:"2rem", marginBottom:"2rem", textDecoration:'none', letterSpacing:3 }}>
                CONNECT
            </p>

            <Grid container spacing={2} style={{width: isMobile? "50%" : "35%", marginBottom:' 5rem' }} >
                <Grid item xs={12} md={12} lg={4} xl={4} style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:'column' }}  >
                      <p style={{ fontFamily: "Times New Roman", color:primaryTxtClr, fontWeight:"bolder" }} >
                        SARA DAMAJ
                      </p>
                      <p style={{ fontFamily: "Times New Roman", color:primaryTxtClr, marginTop:'-0.5rem' }} >
                        +971 559150769
                      </p>
                </Grid>

                <Grid item xs={12} md={12} lg={4} xl={4} style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:'column' }} >
                    <div style={{
                        borderLeft: '1px solid #2c3e50',
                        height: '100px', 
                        margin: '0 20px', 
                    }}/>
                </Grid>

                <Grid item xs={12} md={12} lg={4} xl={4} style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:'column' }}  >
                      <p style={{ fontFamily: "Times New Roman", color:primaryTxtClr, fontWeight:"bolder"  }} >
                        KHALED EL SHERIF 
                      </p>
                      <p style={{ fontFamily: "Times New Roman", color:primaryTxtClr, marginTop:'-0.5rem' }} >
                        +971 563528909
                      </p>
                </Grid>
            </Grid>

            <p className='title-style-head3' style={{  fontFamily: 'Times New Roman', color: primaryTxtClr, marginTop:"2rem", marginBottom:"2rem", textDecoration:'none', letterSpacing:3 }}>
               Q & A
            </p>

            <Accordion style={{ width: '100%', maxWidth: '700px', marginBottom: '1rem', border: `solid 0.5px ${primaryTxtClr  }` }}  >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 'bold', fontSize: !isMobile? '1.15rem' : '0.90rem', color: primaryTxtClr }}>
                        Q: How is the weather at this time of the year?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 300, fontSize: !isMobile? '0.95rem' : '0.75rem', color: primaryTxtClr }}>
                        In December, you can expect warm, sunny days with temperatures around 22-25°C and cooler evenings 16-18°C, making it an ideal spot for a winter wedding.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion style={{ width: '100%', maxWidth: '700px', marginBottom: '1rem', border: `solid 0.5px ${primaryTxtClr}` }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 'bold', fontSize: !isMobile? '1.15rem' : '0.90rem', color: primaryTxtClr }}>
                        Q: When is the best time to arrive to El Gouna?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 300, fontSize: !isMobile? '0.95rem' : '0.75rem', color: primaryTxtClr }}>
                        We recommend that guests arrive in Gouna on the evening of the 18th to settle in and relax before the big day. Sara and Khaled will be on standby to meet up with everyone who arrives that day for something casual. To know more please reach out to bride or groom.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion style={{ width: '100%', maxWidth: '700px', marginBottom: '1rem', border: `solid 0.5px ${primaryTxtClr}` }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 'bold', fontSize: !isMobile? '1.15rem' : '0.90rem', color: primaryTxtClr }}>
                        Q: What are the plans for guests arriving on December 18th?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 300, fontSize: !isMobile? '0.95rem' : '0.75rem', color: primaryTxtClr }}>
                        For those arriving on December 18th, we will be going to Pier 88. You’re welcome to join us—please contact either myself or Khaled for more information.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion style={{ width: '100%', maxWidth: '700px', marginBottom: '1rem', border: `solid 0.5px ${primaryTxtClr}` }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 'bold', fontSize: !isMobile? '1.15rem' : '0.90rem', color: primaryTxtClr }}>
                        Q: How can guests arrange professional hair and makeup services?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: primaryClr }}>
                    <Typography style={{ fontFamily: 'Times New Roman', fontWeight: 300, fontSize: !isMobile? '0.95rem' : '0.75rem', color: primaryTxtClr }}>
                        If a guest would like to arrange hair and makeup services, please contact us at least 10 days in advance to ensure we can accommodate your request.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default QuestAndAns;

